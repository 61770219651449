import React from 'react'
import Layout from '../components/Layout/Layout'
import Navigation from '../components/Navigation/Navigation'
import Seo from '../components/Seo/Seo'

import '../scss/pages/default.scss';

export default function Default({pageContext}) {
    const heading = pageContext.heading
    const content = pageContext.content
    const seo = pageContext.seo

    return (
        <Layout>
            <Seo title={seo.title} description={seo.metaDesc} keywords={seo.focuskw} pathname={seo.opengraphUrl}/>
            <div className="default-page">
                <div id="page-header" className="header">
                    <Navigation/>
                    <div className="container pt-30">
                        <div className="row">
                            <div className="col-lg-12 col-sm-12 col-xs-12">
                                <p className="header-tile">{pageContext.tile}</p>
                                <h1 className="text-white title pt-45">{heading}</h1>
                                <p className="paragraph text-white mb-0 container-paragraph">{pageContext.header_content}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container pt-50 pb-50">
                    <div className="row">
                        <div class="content" dangerouslySetInnerHTML={{ __html: content }}></div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
